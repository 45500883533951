import {getRecord, getRecords, postRecord, putRecord, removeRecord} from "@/services/api";

export function getManagers(filterQuery, options = {}) {
    filterQuery.datatable = true
    if (options.toggleProgressSpinner !== true) options.toggleProgressSpinner = false
    return getRecords('/prologistics/dashboard/managers.json', filterQuery, options)
}

export function getManager(id, filterQuery = {}, options = {}) {
    return getRecord('/prologistics/dashboard/managers/' + id + '.json', filterQuery, options)
}

export function postManager(data, options = {}) {
    return postRecord('/prologistics/dashboard/managers.json', data, options)
}

export function putManager(id, data, options = {}) {
    return putRecord('/prologistics/dashboard/managers/' + id + '.json', data, options)
}

export function removeManager(id, options = {}) {
    return removeRecord('/prologistics/dashboard/managers/', id, options)
}